<template>
  <login-wrapper>
    <template v-slot:header>
      <zubut-image class="test" />
    </template>

    <template v-slot:section>
      <div v-show="emailSend">
        <div class="title mb-3">¡Ya casi, un paso más!</div>
        <p>
          Te enviamos las instrucciones para recuperar tu contraseña a
          {{ emailSent }}
        </p>
      </div>
      <div v-show="hasErrors">
        <div class="title mb-3">¡Tenemos un problema!</div>
        <p>
          Tenemos problemas localizando tu usuario, por favor revisa que tu
          correo sea el correcto.
        </p>
      </div>
      <div>
        <div class="title" :class="{ 'd-none': emailSend || hasErrors }">
          Para recuperar tu contraseña escribe tu correo
        </div>
        <div>
          <b-input
            id="email"
            v-model="email"
            :state="emailState"
            type="email"
            class="form-control"
            placeholder="Escribe tu correo electrónico"
          />
        </div>
      </div>
      <div class="mt-3">
        <div class="mb-5">
          <router-link :to="{ name: 'login' }">
            Ingresar con mis datos de usuario
          </router-link>
        </div>
        <z-button
          class="z-action"
          expanded
          :disabled="!validForm"
          :loading="isLoading"
          @click="dispatchRecoverPassword"
        >
          Recuperar contraseña
        </z-button>
      </div>
    </template>

    <template v-slot:footer>
      <login-footer />
    </template>
  </login-wrapper>
</template>

<script>
import { validateEmail } from "@/utils/strings";
import LoginFooter from "./LoginFooter";
import LoginWrapper from "./LoginWrapper";
import ZubutImage from "./ZubutImage";

export default {
  name: "Forgot",
  components: { LoginFooter, LoginWrapper, ZubutImage },

  data() {
    return {
      email: "",
      emailSent: "",
      isLoading: false,
      emailSend: false,
      hasErrors: false
    };
  },

  computed: {
    emailState() {
      return this.email.length === 0 ? null : validateEmail(this.email);
    },

    validForm() {
      return this.emailState && !this.isLoading;
    }
  },

  methods: {
    dispatchRecoverPassword: function() {
      this.isLoading = true;
      this.$store
        .dispatch("user/passwordRecovery", this.email)
        .then(() => {
          this.emailSend = true;
          this.hasErrors = false;
          this.emailSent = this.email;
          this.email = "";
        })
        .catch(err => {
          this.$captureError(err);
          this.emailSend = false;
          this.hasErrors = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.z-action {
  height: 40px;
  font-weight: 600;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1em;
}
</style>
